
import { Content } from '@/data/@types/Content'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { Index } from '@/data/@types/Index'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useRouterExtention } from '@/helpers/hooks/useRouterExtention'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ItemTree',
  props: {
    item: {
      type: Object,
      required: true,
    },
    n: {
      type: Number,
      default: 1,
    },
    current: {
      type: Boolean,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const routerExtention = useRouterExtention(route, router)

    const hasIndicesBundle = (bundle: ContentsBundle) => bundle.indices !== null && bundle.indices?.length > 0

    const hasContentIndex = (contentsBundle: ContentsBundle, index: number | undefined): boolean => {
      if (index === 0 || index) return !!contentsBundle.contents?.[index]?.publicPath
      else return false
    }

    // ハイライトされるコンテンツIDを取得
    const getHighlightContentId = computed(() => {
      const indices = store.getters.currentContentsBundle?.indices
      // コンテンツ番号を持つURLかどうか判定
      const hasContentNumberInPath = !!route.path.split('pid/')[1].split('/')[2]
      // ファイルビューア以外でコンテンツが選択されていないときは、目次をハイライトする
      if (!indices) return props.viewerType !== 'TheFileViewer' || hasContentNumberInPath ? store.getters.currentContent?.id : ''

      const currentContentNumber = store.getters.contentNumber
      const contentsList = store.getters.currentContentsBundle?.contents
      if (!contentsList) return ''
      const currentContentId = store.getters.currentContent?.id
      // コンテンツに紐づく目次があるとき
      const contentInIndices = indices.find((index: Index) => index.contentId === currentContentId)
      if (contentInIndices) return contentInIndices.contentId
      // コンテンツに紐づく目次がないとき
      for (let checkContentNumber = currentContentNumber - 1; checkContentNumber >= 0; checkContentNumber--) {
        const checkContentId = contentsList[checkContentNumber].id
        const contentNotInIndices = indices.find((index: Index) => index.contentId === checkContentId)
        if (contentNotInIndices) return contentNotInIndices.contentId
      }
      // 現在選択したコンテンツ番号より小さいコンテンツ番号のコンテンツが１つも目次にないとき
      return ''
    })

    /**
     * @returns 紐づくコンテンツがあればそのパス、なければundefined
     */
    const selectedContentRoutingPath = (contentId: string, pid: string, contents: Content[], bundleIndex: number, contentIdMap: Map<string, number>): string | undefined => {
      const contentIndex = contentIdMap.get(contentId)
      if (contentIndex !== 0 && !contentIndex) return
      return '/' + makeUpdatedPidString(pid) + '/' + (bundleIndex + 1) + '/' + (contentIndex + 1)
    }

    // 目次タブでの最初のcurrentコンテンツ
    const isCurrentFirstContent = (indices: Array<any>, currentIndicesNumber: number) => {
      if ((currentIndicesNumber - 1) < 0) return true
      if (indices[currentIndicesNumber - 1]?.contentId !== indices[currentIndicesNumber]?.contentId) return true
      return false
    }

    // 目次タブでの最後のcurrentコンテンツ
    const isCurrentLastContent = (indices: Array<any>, currentIndicesNumber: number) => {
      if (currentIndicesNumber === (indices.length - 1)) return true
      if (indices[currentIndicesNumber + 1]?.contentId !== indices[currentIndicesNumber]?.contentId) return true
      return false
    }

    const sortContents = (contents: Array<Content>) => {
      return contents
        ?.sort((a, b) => {
          if (a.sortKey < b.sortKey) return -1
          else if (a.sortKey > b.sortKey) return 1
          else return 0
        }) || []
    }

    const sortedContentsMap = new Map<string, Array<Content>>()
    const contentIdMaps = new Map<string, Map<string, number>>()
    for (let i = 0; i < props.item.contentsBundles.length; i++) {
      const sortedContents = sortContents(props.item.contentsBundles[i].contents)
      const contentIdMap = new Map<string, number>()
      for (let j = 0; j < sortedContents.length; j++) {
        contentIdMap.set(sortedContents[j].id, j)
      }
      sortedContentsMap.set(props.item.contentsBundles[i].id, sortedContents)
      contentIdMaps.set(props.item.contentsBundles[i].id, contentIdMap)
    }

    const updateViewer = (val: number) => {
      store.commit('updateCurrentBundleIndex', {
        currentBundleIndex: val,
      })
    }

    const updateContent = (val: number) => {
      console.log('updateCurrentContentIndex 06')
      store.commit('updateCurrentContentIndex', {
        currentContentIndex: val,
      })
    }

    const isModifyPid = routerExtention.isModifyPid

    const changeSelectLink = (path: string, bundleIndex: number) => {
      routerExtention.replaceOrPush(path)
      updateViewer(bundleIndex)
      updateContent(Number(path.split('/')[4]) - 1 || 0) // バンドル名称をクリックした際はContentIndexが0のコンテンツを表示する。
    }

    return {
      lang,
      selectedContentRoutingPath,
      getHighlightContentId,
      sortContents,
      pid: props.item.pid,
      contentsBundles: props.item.contentsBundles,
      hasIndicesBundle,
      sortedContentsMap,
      contentIdMaps,
      hasContentIndex,
      makeUpdatedPidString,
      changeSelectLink,
      isModifyPid,
      isCurrentFirstContent,
      isCurrentLastContent,
    }
  },
})
