import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68841aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "contents-bundle-title"
}
const _hoisted_2 = ["href", "onClick", "onKeydown"]
const _hoisted_3 = ["href", "onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["contents-bundle-index", `children-${_ctx.n}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentsBundles, (contentsBundle, bundleIndex) => {
      return (_openBlock(), _createElementBlock("li", {
        key: contentsBundle.directoryName
      }, [
        (_ctx.contentsBundles.length > 1 && contentsBundle.name?.ja)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("a", {
                href: '/' + _ctx.makeUpdatedPidString(_ctx.pid) + '/' + (bundleIndex + 1),
                onClick: _withModifiers(($event: any) => (_ctx.changeSelectLink('/' + _ctx.makeUpdatedPidString(_ctx.pid) + '/' + (bundleIndex + 1), bundleIndex)), ["prevent"]),
                onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.changeSelectLink('/' + _ctx.makeUpdatedPidString(_ctx.pid) + '/' + (bundleIndex + 1), bundleIndex)), ["prevent"]), ["space","enter"])
              }, _toDisplayString(contentsBundle.name?.ja), 41, _hoisted_2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasIndicesBundle(contentsBundle))
          ? (_openBlock(), _createElementBlock("ul", {
              key: 1,
              class: _normalizeClass({ [`children-${_ctx.n + 1}`]: _ctx.contentsBundles.length > 1})
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contentsBundle.indices, (content, indicesIndex) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["contents-bundle-index-contents-title", {
            current: _ctx.current && content.contentId === _ctx.getHighlightContentId,
            'first-content': _ctx.isCurrentFirstContent(contentsBundle.indices, indicesIndex),
            'last-content': _ctx.isCurrentLastContent(contentsBundle.indices, indicesIndex)
          }]),
                  key: content.articleId
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasContentIndex(contentsBundle, _ctx.contentIdMaps.get(contentsBundle.id).get(content.contentId)) ? 'a' : 'span'), {
                    href: _ctx.selectedContentRoutingPath(content.contentId, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)),
                    onClick: _withModifiers(($event: any) => (_ctx.hasContentIndex(contentsBundle, _ctx.contentIdMaps.get(contentsBundle.id).get(content.contentId)) && _ctx.changeSelectLink(_ctx.selectedContentRoutingPath(content.contentId, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)), bundleIndex)), ["prevent"]),
                    onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.hasContentIndex(contentsBundle, _ctx.contentIdMaps.get(contentsBundle.id).get(content.contentId)) && _ctx.changeSelectLink(_ctx.selectedContentRoutingPath(content.contentId, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)), bundleIndex)), ["prevent"]), ["space","enter"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(content.index), 1)
                    ]),
                    _: 2
                  }, 1032, ["href", "onClick", "onKeydown"]))
                ], 2))
              }), 128))
            ], 2))
          : (_openBlock(), _createElementBlock("ul", {
              key: 2,
              class: _normalizeClass({ [`children-${_ctx.n + 1}`]: _ctx.contentsBundles.length > 1})
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedContentsMap.get(contentsBundle.id), (content) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["contents-bundle-index-contents-title", {
            current: _ctx.current && content.id === _ctx.getHighlightContentId,
            'first-content': true,
            'last-content': true
          }]),
                  key: content.id
                }, [
                  _createElementVNode("a", {
                    href: _ctx.selectedContentRoutingPath(content.id, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)),
                    onClick: _withModifiers(($event: any) => (_ctx.changeSelectLink(_ctx.selectedContentRoutingPath(content.id, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)), bundleIndex)), ["prevent"]),
                    onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.changeSelectLink(_ctx.selectedContentRoutingPath(content.id, _ctx.pid, contentsBundle.contents, bundleIndex, _ctx.contentIdMaps.get(contentsBundle.id)), bundleIndex)), ["prevent"]), ["space","enter"])
                  }, _toDisplayString(content.name?.ja || content.originalFileName), 41, _hoisted_3)
                ], 2))
              }), 128))
            ], 2))
      ]))
    }), 128))
  ], 2))
}